/* App.css or any global CSS file */


/* App.css or any global CSS file */
/* @media print {
    body * {
      visibility: hidden;
    }

    .print-container, .print-container * {
      visibility: visible;
    }

    .print-container {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    } */

/* .print-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;

        margin: 0 auto;

        box-sizing: border-box;
      } */
/* } */
@media print {

    html,
    body {
        height: 100%;
        margin: 0 !important;
        padding: 0 !important;
        display: block;
        /* overflow: hidden; */
    }
    .page-break {
        page-break-after: always;
        break-after: always;
    }
    .print-only td { width: '250px'; display: inline-block;}
    .print-only th {font-weight: bold;}
}

@media screen {
    .print-only {
        visibility: hidden;
        display: none;
    }
}

dd {
    margin-left: 80px;
}

.sign-con{
    width: 300px; 
    height: 70px;
    text-align: 'center';
    line-height: 70px;
}

@media print {
    .print-pharmacy-only {
        display: block;
    }
    
    .no-print {
        display: none;
      }
}

@media screen {
    .print-pharmacy-only {
        display: none;
    }
}

